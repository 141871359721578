import React from 'react';

const Cac = () => {
	return (
		<div className='container'
		style={{
			display: 'flex',
			justifyContent: 'left',
			alignItems: 'left',
			height: '100vh'
		}}
		>
		<h1>Community Action Collab</h1>
		</div>
	);
};

export default Cac;